<template>
  <v-container>
    <v-dialog v-model="formMain.show" persistent max-width="600px">
      <v-form v-on:submit.prevent ref="form-main" v-model="formMain.valid">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="headline">{{formMain.isAddMode ? 'Add' : 'Edit'}} Assets Category</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field label="Name *" v-model="formMain.model.name" :rules="formMain.rules.name" v-on:keydown.enter="saveForm"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text v-on:click="formMain.show = false">
              Cancel
            </v-btn>
            <v-btn v-if="formMain.isAddMode" color="primary" :disabled="!formMain.valid" v-on:click="saveForm">
              Add
            </v-btn>
            <v-btn v-else color="primary" :disabled="!formMain.valid" v-on:click="saveForm">
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dgDelete.show" max-width="300">
      <v-card>
        <v-card-title class="headline">
          Delete?
        </v-card-title>
        <v-card-text>Are you sure to delete category: {{dgDelete.item ? dgDelete.item.name : '--'}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text v-on:click="dgDelete.show = false">
            Cancel
          </v-btn>
          <v-btn color="error" v-on:click="removeItem(dgDelete.item)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="my-1">
      <v-col cols="12" sm="3">
        <v-btn elevation="4" color="success" block v-on:click="addItem"><v-icon left>mdi-plus</v-icon>Add</v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="3">
        <v-btn elevation="4" color="info" block v-on:click="viewItem()">View All<v-icon right>mdi-chevron-right</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="items" item-key="name" :search="search" v-model="selected" :show-select="false">
      <template v-slot:top>
        <v-card color="primary white--text pa-2">
          <v-row align="center" no-gutters>
            <v-col cols="12" sm="8">
              <v-card-title>Assets Category</v-card-title>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="search" clearable flat solo hide-details prepend-inner-icon="mdi-magnify" label="Search"></v-text-field>
            </v-col>
          </v-row>

        </v-card>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="item.id && item.id > 0" icon v-on:click="editItem(item)" v-bind="attrs" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="item.id && item.id > 0" icon v-on:click="confirmRemove(item)" v-bind="attrs" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template v-slot:item.actions-view="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on:click="viewItem(item)" v-bind="attrs" v-on="on"><v-icon>mdi-chevron-right</v-icon></v-btn>
          </template>
          <span>View Details</span>
        </v-tooltip>
      </template>
      <!-- <template v-slot:body.append>
        <tr>
          <td></td>
          <td>
            <v-text-field v-model="calories" type="number" label="less"></v-text-field>
          </td>
          <td colspan="4"></td>
        </tr>
      </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data: function() {
    return {
      search: "",
      headers: [
        { text: 'Category', value: 'name' },
        { text: 'Quantity', value: 'assets_count'},
        { text: 'Actions', value: 'actions', sortable: false, width: '120px' },
        { text: 'View', value: 'actions-view', sortable: false, width: '60px' }
      ],
      items: [],
      selected: [],
      editingItem: null,
      dgDelete: {
        show: false,
        item: null,
      },
      formItems: {
        roles: [],
      },
      formMain: {
        show: false,
        isAddMode: true,
        valid: false,
        model: {
          id: null,
          name: null,
        },
        rules: {
          name: [
            v => !!v || 'Required'
          ],
        }
      },
    }
  },
  mounted() {
    this.getAllItems();
    this.getExtraItems();
  },
  methods: {
    getAllItems: function() {
      var _this = this;
      this.get('/assetscategory/getall', function(res){
        _this.items = res['data']['data']
      })
    },
    getExtraItems: function() {
      var _this = this;
      this.get('/role/getall', function(res){
        _this.formItems.roles = res['data']['data']
      })
    },
    addItem: function() {
      this.formMain.isAddMode = true;
      this.formMain.show = true;
      this.resetForm();
    },
    saveForm: function() {
      if (!this.formMain.valid) return;

      var url = '/assetscategory/add';
      if(!this.formMain.isAddMode)
        url = '/assetscategory/update/' + this.formMain.model.id;

      var _this = this;
      this.formMain.show = false;
      this.post(url, this.formMain.model, function(){
        _this.formMain.show = false;
        _this.getAllItems();
        _this.resetForm();
      }, function(){
        _this.formMain.show = true;
      }, {showSuccess: true, showError: true, showLoading: true});
    },
    resetForm: function() {
      this.formMain.model.id = null;
      this.formMain.model.name = null;
      this.$refs['form-main'].resetValidation();
    },
    editItem: function(item) {
      this.formMain.isAddMode = false;
      this.editingItem = JSON.parse(JSON.stringify(item));
      this.formMain.model = this.editingItem;
      this.formMain.show = true;
    },
    confirmRemove: function(item) {
      this.dgDelete.item = item;
      this.dgDelete.show = true;
    },
    removeItem: function(item) {
      var _this = this;
      this.dgDelete.show = false;
      
      this.post('/assetscategory/delete/' + item.id, null, function(){
        _this.getAllItems();
      })
    },
    viewItem: function(item) {
        if(item){
            if(item.id)
                this.navTo("/Assets/Details/" + item.id);
            else
                this.navTo("/Assets/Details/-1");
        }
        else
            this.navTo("/Assets/Details");
    }
  }
}
</script>